import { MDXProvider } from "@mdx-js/react"
import { graphql, Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout from "../components/Layout"
import {
  Header1,
  Header2,
  Header4,
  ImageProject,
  paragraph,
} from "../components/mdx-styles"
import Seo from "../components/seo"

const shortcodes = {
  Link,
  h1: Header1,
  h2: Header2,
  h4: Header4,
  p: paragraph,
  img: ImageProject,
}

export default function PageTemplate({ data: { mdx } }) {
  const { frontmatter } = mdx
  const imagePath = getSrc(frontmatter.ogimage)
  return (
    <Layout projects>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description}
        ogImage={imagePath}
      />
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        ogimage {
          childImageSharp {
            gatsbyImageData(width: 1200, formats: [PNG])
          }
        }
      }
    }
  }
`
