import styled from "styled-components"

export const Header1 = styled.h1`
  text-align: center;
`
export const Header2 = styled.h1`
  margin-top: 3.5rem;
`
export const Header4 = styled.h4`
  color: #f5b461;
  text-transform: capitalize;
  font-style: italic;
`
export const paragraph = styled.p`
  line-height: 1.6;
`
export const ImageProject = styled.img`
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5) !important;
  padding: 0;
`
